// Generated by Framer (7f69244)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["scRAfJCjT", "u5SsUD80A"];

const serializationHash = "framer-IXSC8"

const variantClassNames = {scRAfJCjT: "framer-v-1k1avew", u5SsUD80A: "framer-v-mszgmy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Closed: "scRAfJCjT", Open: "u5SsUD80A"}

const getProps = ({color, height, id, width, ...props}) => { return {...props, dHif14b7c: color ?? props.dHif14b7c ?? "rgb(170, 170, 170)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "scRAfJCjT"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dHif14b7c, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "scRAfJCjT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1k1avew", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"scRAfJCjT"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({u5SsUD80A: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1m5umur"} data-framer-name={"icon"} layoutDependency={layoutDependency} layoutId={"JY7nMOS24"} style={{rotate: 0}} variants={{u5SsUD80A: {rotate: -180}}}><ComponentViewportProvider ><motion.div className={"framer-xhi8p3-container"} layoutDependency={layoutDependency} layoutId={"P5qYoegBU-container"}><Phosphor color={dHif14b7c} height={"100%"} iconSearch={"House"} iconSelection={"CaretDown"} id={"P5qYoegBU"} layoutId={"P5qYoegBU"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IXSC8.framer-6fkxou, .framer-IXSC8 .framer-6fkxou { display: block; }", ".framer-IXSC8.framer-1k1avew { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-IXSC8 .framer-1m5umur { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 14px; justify-content: center; left: calc(50.00000000000002% - 14px / 2); overflow: visible; padding: 0px; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-IXSC8 .framer-xhi8p3-container { flex: none; height: 12px; position: relative; width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IXSC8 .framer-1m5umur { gap: 0px; } .framer-IXSC8 .framer-1m5umur > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-IXSC8 .framer-1m5umur > :first-child { margin-left: 0px; } .framer-IXSC8 .framer-1m5umur > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"u5SsUD80A":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dHif14b7c":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzqR0KIhjI: React.ComponentType<Props> = withCSS(Component, css, "framer-IXSC8") as typeof Component;
export default FramerzqR0KIhjI;

FramerzqR0KIhjI.displayName = "Elements / Caret";

FramerzqR0KIhjI.defaultProps = {height: 14, width: 14};

addPropertyControls(FramerzqR0KIhjI, {variant: {options: ["scRAfJCjT", "u5SsUD80A"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, dHif14b7c: {defaultValue: "rgb(170, 170, 170)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerzqR0KIhjI, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})